import { v4 as uuidv4 } from 'uuid'

const SESSION_KEY: string = 'userSessionId'

const getSessionId = (): string => {
  const sessionId = localStorage.getItem(SESSION_KEY)
  if (sessionId === null) {
    const newSessionId = uuidv4()
    localStorage.setItem(SESSION_KEY, newSessionId)
    return newSessionId
  }
  return sessionId
}

export { getSessionId }
