import ReactDOM from 'react-dom/client'
import { AppProvider } from 'context'
import './styles/index.css'
import App from './App'
import { reportWebVitals } from './reportWebVitals'
import React from 'react'
import '@mantine/core/styles.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
    <AppProvider>
        <App />
    </AppProvider>
    </React.StrictMode>
)

reportWebVitals()
