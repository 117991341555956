import { Avatar, Indicator } from '@mantine/core'
import { useNetwork } from '@mantine/hooks'
import { Close, SendRounded } from '@mui/icons-material'
import ChatIcon from '@mui/icons-material/Chat'
import { Button } from 'antd'
import { useBiBotChatBot } from 'hooks'

interface bubleProps {
  Chatcolor?: string
  title?: string
  bgColor?: string
  userImage?: string
}

const ChatBubble = ({ bgColor, Chatcolor, title, userImage }: bubleProps) => {
  const {
    chatIsOpen,
    messages,
    isLoading,
    messageEndRef,
    userInput,
    handleUserInput,
    handleKeyPress,
    sendInputInquiry,
    toggleChat
  } = useBiBotChatBot()
  const networkStatus = useNetwork()
  return (
    <div className={`chat-bubble ${chatIsOpen ? 'open' : ''}`}>
      {chatIsOpen && (
        <div className="chat-window" style={{ backgroundColor: bgColor }}>
          <div className="chat-header" style={{ backgroundColor: Chatcolor ?? '#dedede' }}>
            <div style={{ alignItems: 'center', display: 'flex', color: '#fff', fontWeight: 'bold' }}>
            <Indicator inline size={12} offset={7} position="bottom-end" color={ networkStatus.online ? 'green' : 'gray'}>
            <Avatar color="white" src={userImage} alt="profile" />
              </Indicator>
               </div>
               <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: '10px', justifyContent: 'flex-start', color: '#fff' }}>
                  <span style={{ fontWeight: 'bold' }}>{title}</span>
                <span style={{ fontSize: '12px', lineHeight: '0.5' }}>{networkStatus.online ? 'Online' : 'Offline'}</span>
                </div>
            </div>
          <div className="message-list">
            {messages.map((message: { sender: 'user' | 'bot', text: string }, index: number) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
              </div>
            ))}
            {isLoading && <div className="loading-bubbles">
              <div className="bubble" style={{ backgroundColor: Chatcolor }}></div>
              <div className="bubble" style={{ backgroundColor: Chatcolor }}></div>
              <div className="bubble" style={{ backgroundColor: Chatcolor }}></div>
            </div>}
            <div ref={messageEndRef} />
          </div>
          <div className="input-area">
            <input
              type="text"
              value={userInput}
              onChange={handleUserInput}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
            />
            <Button style={{ backgroundColor: 'transparent', color: Chatcolor }} size='middle' icon={<SendRounded />} onClick={sendInputInquiry}/>
          </div>
        </div>
      )}
      <Button type='link' onClick={toggleChat} className="chat-toggle" style={{ backgroundColor: Chatcolor ?? '#dedede' }}>
        {chatIsOpen ? <Close style={{ width: 30, height: 30, color: '#fff' }} /> : <ChatIcon style={{ width: 30, height: 30, color: '#fff' }}/>}
      </Button>
    </div>
  )
}

export default ChatBubble
