export const ROUTES = {
  HOME: '/',
  OVERVIEW: '/overview',
  LANDING_PAGE: '/home',
  ADMIN: '/admin',
  NOTFOUND: '*',
  ERROR: '/error',
  ABOUT: '/about-us',
  ABOUT_SOC: '/about-soc',
  SERVICES: '/services',
  ABOUT_STATE_RAMP: '/about-state-ramp',
  DEMO: '/request-a-demo',
  SOC2: '/soc2',
  AWS: '/aws',
  STATE_RAMP: '/state-ramp',
  CONTACT_US: '/contact-us'
}
