import { createElement } from 'react'
import type React from 'react'
import AssessmentIcon from '@mui/icons-material/Assessment'
import BlockIcon from '@mui/icons-material/Block'
import SpeedIcon from '@mui/icons-material/Speed'
import SecurityIcon from '@mui/icons-material/Security'
import MonitorIcon from '@mui/icons-material/Monitor'
import ConstructionIcon from '@mui/icons-material/Construction'
import KeyIcon from '@mui/icons-material/Key'
import ShieldIcon from '@mui/icons-material/Shield'

interface listItems {
  content?: string
  title: string
  icon?: React.ReactNode
}

export const services: listItems[] = [
  { icon: createElement(AssessmentIcon), title: 'PreAuditAi', content: 'PreAuditAi is CloudSec LLC\'s flagship automated auditing tool, revolutionizing the way AWS accounts are audited. By leveraging advanced AI and machine learning technologies, PreAuditAi cuts down auditing time from months to mere minutes, offering comprehensive coverage across key regulatory and security frameworks such as SOC2, NIST, GDPR, HIPAA, FedRamp, StateRamp, ISO27001, and PCI DSS. This tool is designed to streamline the audit process, ensuring that your cloud environment adheres to the highest standards of security and compliance with minimal effort.' },
  { icon: createElement(BlockIcon), title: 'Remediation', content: 'Following an assessment, our Remediation service steps in to address and rectify the findings pinpointed by PreAuditAi. CloudSec LLC understands the complexities involved in navigating through the remediation process. That\'s why we offer expert guidance and solutions to mitigate any issues, ensuring that your AWS environment not only meets but exceeds compliance and security benchmarks. Our team works closely with customers to implement effective remediation strategies, turning potential vulnerabilities into strengths.' },
  { icon: createElement(SpeedIcon), title: 'Landing Zone Accelerator', content: 'CloudSec LLC\'s Landing Zone Accelerator service is designed to help customers establish a robust and secure AWS foundation, enabling them to scale their operations with confidence. By leveraging our expertise in cloud architecture and security best practices, we assist in building a solid landing zone that serves as a strong foundation for your cloud journey. This service ensures that your initial AWS setup is optimized for security, compliance, and operational efficiency, paving the way for a smooth and secure scale-up.' },
  { icon: createElement(SecurityIcon), title: 'Perimeter Protection', content: 'Our Parameter Protection service focuses on fortifying your cloud environment against external and internal threats. By implementing state-of-the-art firewall solutions and security protocols, we ensure that your AWS resources are shielded from unauthorized access and potential vulnerabilities. This service is all about creating a secure perimeter around your cloud assets, providing peace of mind that your data and applications are protected from the ever-evolving landscape of cyber threats.' },
  { icon: createElement(MonitorIcon), title: 'Traceability', content: 'Traceability, offered by CloudSec LLC, enhances your cloud environment\'s security posture through comprehensive logging and monitoring solutions. Our service ensures that all actions within your AWS account are meticulously tracked and recorded, enabling swift detection and analysis of any suspicious activities. With Traceability, customers gain valuable insights into their cloud operations, facilitating a proactive approach to security and compliance management.' },
  { icon: createElement(ConstructionIcon), title: 'Patch Management', content: 'Keeping your cloud environment secure means ensuring that all systems are up-to-date with the latest security patches. CloudSec LLC\'s Patch Management service automates the process of applying patches across your AWS infrastructure, reducing the risk of vulnerabilities that could be exploited by cyber threats. Our approach ensures that your systems are always running the latest software versions, minimizing downtime and securing your operations against potential breaches' },
  { icon: createElement(ShieldIcon), title: 'DevSecOps', content: 'CloudSec LLC integrates security into the heart of your development and operational processes with our DevSecOps service. Focusing on CI/CD pipeline security, we embed automated security tools and practices throughout the software development lifecycle, ensuring that security is a priority from the start. Our service helps to identify and address security issues early on, making your applications more resilient against attacks and ensuring they are built on a foundation of security.' },
  { icon: createElement(KeyIcon), title: 'IAM - Access Reviews', content: 'Effective Identity and Access Management (IAM) is crucial for maintaining the security of your AWS environment. CloudSec LLC\'s IAM - Access Reviews service provides thorough evaluations and management of user permissions and access rights, ensuring that only authorized personnel have access to sensitive resources. By regularly reviewing and adjusting access controls, we help prevent unauthorized access and potential security breaches, reinforcing the overall security of your cloud infrastructure.' }
]
