import React, { useContext, useEffect } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Layout } from 'antd'
import logo from '../../../assets/logo/logo.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const { Content } = Layout

const HomePage = () => {
  return (
    <Layout className="home-root">
      <Header />
      <Content className="home-content">
        <div className="home-container">
          <div className="text-section">
            <h2 style={{ marginBottom: '0px' }}>Transform your approach to IT compliance</h2>
            <p style={{ fontSize: '20px' }}>
              At PreAuditAi, we utilize AI technology to transform your approach
              to IT compliance. We streamline pre-assessments from months-long
              processes to tasks accomplished in hours, swiftly identifying
              compliance issues and suggesting solutions. Contact us today and
              turn compliance into your strategic advantage.
            </p>
            <a href={
              process.env.REACT_APP_ENV === 'dev'
                ? 'https://app.dev.preauditai.com'
                : 'https://app.preauditai.com'
            }
            target="_blank"
            rel="noopener noreferrer" style={{ width: '100%' }}>
            <button className='home-button' >Get started <KeyboardArrowRightIcon style={{ width: 30, height: 30 }}/></button>
            </a>
          </div>
          <div className="image-section">
            <img src={logo} alt="Rocket" className='pre-audit-logo' />
          </div>
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default HomePage
