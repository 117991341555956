import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu, Button, Layout, Drawer, message } from 'antd'
import { DownOutlined, MenuOutlined } from '@ant-design/icons'
import logo from '../../../assets/logo/favicon-white.png'
import { ROUTES } from 'config/constants'

const { Header } = Layout

const AppHeader = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const menuItems = [
    {
      label: 'Home',
      key: 'home',
      onClick: async () => {
        try {
          navigate(ROUTES.HOME)
        } catch (error: any) {
          void message.error(error?.message || 'There was an error navigating home. Please refresh your browser.')
        }
      }
    },
    {
      label: 'Services',
      key: 'services',
      onClick: async () => {
        try {
          navigate(ROUTES.SERVICES)
        } catch (error: any) {

        }
      }
      // icon: !visible ? <DownOutlined/> : null,
      // children: [
      //   {
      //     label: 'AWS Security Assessment',
      //     key: 'aws',
      //     onClick: async () => {
      //       try {
      //         navigate(ROUTES.ABOUT_AWS)
      //       } catch (error: any) {

      //       }
      //     }
      //   },
      //   {
      //     label: 'SOC 2 Readiness Pre-Assessment',
      //     key: 'soc',
      //     onClick: async () => {
      //       try {
      //         navigate(ROUTES.ABOUT_SOC)
      //       } catch (error: any) {

      //       }
      //     }
      //   },
      //   {
      //     label: 'StateRamp Readiness Pre-Assessment',
      //     key: 'stateRamp',
      //     onClick: async () => {
      //       try {
      //         navigate(ROUTES.ABOUT_STATE_RAMP)
      //       } catch (error: any) {

      //       }
      //     }
      //   }
      // ]
    },
    {
      label: 'About Us',
      key: 'about-us',
      onClick: async () => {
        try {
          navigate(ROUTES.ABOUT)
        } catch (error: any) {

        }
      }
    },
    {
      label: 'Request a Demo',
      key: 'request-a-demo',
      onClick: async () => {
        try {
          navigate(ROUTES.CONTACT_US)
        } catch (error: any) {

        }
      }
    }
  ]

  return (
    <Header className="header">
      <Link to="/">
        <img
          src={logo}
          className="logo"
          alt="logo"
        />
      </Link>
      <div className="desktop-menu">
        <Menu
          theme="dark"
          mode="horizontal"
          items={menuItems}
        />
      </div>
      <div className="mobile-menu">
        <Button
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => { setVisible(true) }}
        />
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => { setVisible(false) }}
          open={visible}
        >
          <Menu
            theme="dark"
            mode="inline"
            items={menuItems}
          />
        </Drawer>
      </div>
    </Header>
  )
}

export default AppHeader
