// NotFoundPage.tsx
import React from 'react'
import { useNavigate } from 'react-router-dom'
import giphy from 'assets/gen/giphy.webp'
import { ROUTES } from 'config'

const NotFound: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      textAlign: 'center',
      background: '#fff'
    }}>
        <h1>404 - Lost in Cyberspace</h1>
        <p>Oops! It looks like this page took a wrong turn at the last electron.</p>
        <img
            src={giphy}
            alt="Lost astronaut"
            width="300"
            style={{ maxWidth: '100%', height: 'auto' }}
        />
        <p>
          The page you&apos;re looking for got sucked into a black hole. Better
          <br />
          warp back to a known location in the universe!
        </p>
        <button className='button'
            style={{
              margin: '20px',
              padding: '10px 20px',
              background: '#1890ff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
            onClick={() => { navigate(ROUTES.HOME) }}
        >
            Go to Home Page
        </button>
    </div>
  )
}

export default NotFound
