import Header from '../Header/Header'
import './AboutStateRamp.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
const AboutStateRamp = () => {
  const advantagesOfStateRamp = [
    { title: 'Time Saving', content: 'By reducing the compliance process from months to hours, you save significant time and resources, empowering you to focus on other vital business aspects.' },
    { title: 'Risk Mitigation', content: 'Identify potential vulnerabilities in your infrastructure and address them proactively, reducing the risk of non-compliance and potential fines.' },
    { title: 'Accelerate Market Entry', content: ' With expedited compliance, you can quickly tap into the government sector market, gaining a competitive edge.' },
    { title: 'Strategic Focus', content: 'Transform compliance from a stumbling block into a strategic advantage, enhancing your organization\'s reputation and customer trust.' }
  ]
  return (
    <>
      <Header />
      <div className='main-container'>
        <div className='main-content'>
        <h1 className="intro-text text-4xl text-white font-bold mb-8">
        StateRamp Service
        </h1>
        <div >
        <h2 className="text-white text-lg">
            Fast-Track Your StateRamp Compliance Journey with PreAuditAi
            </h2>
            <p className="text-white text-lg">
            The government sector can be a lucrative market, but compliance with regulations like StateRamp can be a daunting task. This hurdle often hinders companies from venturing into this sector, and those who do, find it an exhausting, time-consuming process.</p>
            <p className="text-white text-lg">
            At PreAuditAi, we&apos;ve changed the game. Our robust AI system is designed specifically to revolutionize your StateRamp compliance journey, reducing the typically lengthy process into a matter of hours. Yes, you read it right - compliance with StateRamp, achieved within hours, not months.</p>
            <h2 className="text-white text-lg">
            How Does It Work?
            </h2>
            <p className="text-white text-lg">
            PreAuditAi&apos;s AI-driven system conducts an in-depth review of your infrastructure, evaluating it against StateRamp compliance requirements. Our solution identifies potential gaps, risks, and vulnerabilities that could compromise compliance. Not just identification, we go beyond and provide actionable insights and remediation strategies to resolve the detected issues.</p>
            <h2 className="text-white text-lg">
            Advantages of PreAuditAi&apos;s StateRamp Service
            </h2>
            {advantagesOfStateRamp.map((advantage, index) => {
              return (
                <div key={index}>
                <p style={{ backgroundColor: '#efefef', padding: 10, borderRadius: 6 }}><strong> {advantage.title}:</strong> {advantage.content}<br/></p>
                </div>
              )
            })}
            <p className="text-white text-lg">PreAuditAi&apos;s StateRamp service is designed for speed, efficiency, and utmost compliance. Break away from the traditional, painstaking methods and leap forward with our AI-driven solution. Let PreAuditAi be your partner in the compliance journey, turning StateRamp from a daunting task into a stepping stone for success in the government sector. </p>
            <p className="text-white text-lg">Are you ready to fast-track your compliance process? Connect with us today and let&apos;s revolutionize your StateRamp compliance journey.</p>
             <h3 className="text-white text-lg">
             #AI #StateRamp #Compliance #InformationSecurity #Speed #Efficiency #GovernmentSector
            </h3>
        </div>
        </div>
      </div>
      {/* <div className="container mx-auto py-16">
        <h1 className="text-4xl text-white font-bold mb-8">
        StateRamp Service
        </h1>
        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
          <div className="flex-1">
            <h2 className="text-white text-lg">
            Fast-Track Your StateRamp Compliance Journey with PreAuditAi
            </h2>
            <p className="text-white text-lg">
            The government sector can be a lucrative market, but compliance with regulations like StateRamp can be a daunting task. This hurdle often hinders companies from venturing into this sector, and those who do, find it an exhausting, time-consuming process.</p>
            <p className="text-white text-lg">
            At PreAuditAi, we&apos;ve changed the game. Our robust AI system is designed specifically to revolutionize your StateRamp compliance journey, reducing the typically lengthy process into a matter of hours. Yes, you read it right - compliance with StateRamp, achieved within hours, not months.</p>

            <h2 className="text-white text-lg">
            How Does It Work?
            </h2>
            <p className="text-white text-lg">
            PreAuditAi&apos;s AI-driven system conducts an in-depth review of your infrastructure, evaluating it against StateRamp compliance requirements. Our solution identifies potential gaps, risks, and vulnerabilities that could compromise compliance. Not just identification, we go beyond and provide actionable insights and remediation strategies to resolve the detected issues.</p>

            <h2 className="text-white text-lg">
            Advantages of PreAuditAi&apos;s StateRamp Service
            </h2>
            <ul className="text-white text-lg">
              <li>
               <strong> Time-Saving:</strong> By reducing the compliance process from months to hours, you save significant time and resources, empowering you to focus on other vital business aspects.
              </li>
              <li>
               <strong> Risk Mitigation:</strong> Identify potential vulnerabilities in your infrastructure and address them proactively, reducing the risk of non-compliance and potential fines.
              </li>
              <li>
              <strong> Accelerate Market Entry:</strong>  With expedited compliance, you can quickly tap into the government sector market, gaining a competitive edge.
              </li>
              <li>
              <strong> Strategic Focus:</strong> Transform compliance from a stumbling block into a strategic advantage, enhancing your organization&apos;s reputation and customer trust.
              </li>
            </ul>
            <p className="text-white text-lg">PreAuditAi&apos;s StateRamp service is designed for speed, efficiency, and utmost compliance. Break away from the traditional, painstaking methods and leap forward with our AI-driven solution. Let PreAuditAi be your partner in the compliance journey, turning StateRamp from a daunting task into a stepping stone for success in the government sector. </p>
            <p className="text-white text-lg">Are you ready to fast-track your compliance process? Connect with us today and let&apos;s revolutionize your StateRamp compliance journey.</p>
             <h3 className="text-white text-lg">
             #AI #StateRamp #Compliance #InformationSecurity #Speed #Efficiency #GovernmentSector
            </h3>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default AboutStateRamp
