import Header from '../Header/Header'
import PreAuditLogo from '../../../assets/logo/preauditai-high-resolution-logo-black-transparent.png'
import { Player } from '@lottiefiles/react-lottie-player'
import GIF from '../../../assets/lottieFiles/AIGIF.json'

const About = () => {
  return (
    <>
      <Header />
        <div className='about-us-container'>
           <div className='about-us-content'>
             <div className='intro-text'>
             <h4 className='about-us-header' style={{ fontWeight: 'bolder', fontSize: '40px', color: '#00152a' }}>
          Revolutionize Your Compliance Strategy with AI
          </h4>
          <p>In today&apos;s competitive landscape, we recognize the hurdles you face. Securing your next significant contract means surmounting formidable obstacles, such as SOC2, StateRamp, FedRamp, CMMC, and numerous others. These have traditionally been a slog, laboriously slow, and consuming valuable time and resources.</p>

          <h3 style={{ color: '#00152a', fontWeight: 'bolder', marginBottom: '0px' }}> Welcome to PreAuditAi - Elevating Your IT Compliance Approach</h3><br/>
At PreAuditAi, we leverage powerful, generative AI technology to transform the way you handle pre-assessments. Imagine converting processes that typically take months into accomplishments achieved within a few hours. That&apos;s right - we&apos;re revolutionizing compliance processes by reducing time frames from MONTHS to mere HOURS!
Our sophisticated AI system conducts a comprehensive review of your control environment, identifying potential compliance issues, and suggesting actionable remediation strategies. We do this with an efficiency that far surpasses conventional methods, saving you valuable time and resources.
          <h3>
        Stay Ahead, Stay Compliant, Stay Agile
        </h3>
        <p>With PreAuditAi, navigating the intricate maze of IT compliance no longer needs to be a daunting, prolonged ordeal. We make compliance a breeze, allowing you to focus on other critical aspects of your business.
Ready to revolutionize your compliance journey? Get in touch with us today and let`&apos;s transform compliance from a challenging obligation into your strategic advantage!</p>
<h3>#AI #SOC2 #StateRamp #Compliance #InformationSecurity #Speed #Efficiency</h3>
             </div>

             <div className='gif-animation'>
             <Player className='BotGIF' src={GIF} loop
          autoplay/>
             </div>
           </div>
        </div>
    </>
  )
}

export default About
