import Header from '../Header/Header'
import './About.css'
import React, { useState } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { ROUTES } from 'config'
import { Modal, Button } from '@mantine/core'
import { services } from 'data/services'

const Services = () => {
  const [active, setActive] = useState<number>(0)
  const [opened, { open, close }] = useDisclosure(false)
  const handleActive = (index: number) => {
    setActive(index)
    console.log(index)
  }
  return (
    <div className="space-bg min-h-screen">
      <Modal
        opened={opened}
        onClose={close}
        title={services[active].title}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3
        }}
        centered
      >
        {services[active].content}
      </Modal>
      <Header />
      <div className="container mx-auto py-16">
        <h1
          className="text-3xl text-white font-bold mb-3"
          style={{ textAlign: 'center', marginTop: '30px' }}
        >
          Services
        </h1>
        <div className="services-container desktop-view">
          <div className="services-content">
            <div className="list-items">
              {services.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`services-listItem ${
                      active === index ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleActive(index)
                    }}
                  >
                    <span className="icon-style">{item.icon}</span> {item.title}
                  </li>
                )
              })}
            </div>
            <div className="listItem-Content-Card">
              {services.map((item, index) => {
                return (
                  <>
                    <h2>{active === index ? services[index].title : null}</h2>
                    <p>{active === index ? services[index].content : null}</p>
                  </>
                )
              })}
            </div>
          </div>
        </div>

        {/* mobile view */}
        <div className="mobile-view">
          {services.map((item, index) => {
            return (
              <li
                key={index}
                className={`services-listItem ${
                  active === index ? 'active' : ''
                }`}
                onClick={() => {
                  handleActive(index)
                  open()
                }}
              >
                <span className="icon-style">{item.icon}</span> {item.title}
              </li>
            )
          })}
        </div>
        <div className="get-started-card">
          <h1>SAY HI TO THE FUTURE.</h1>
          <p>Let&apos;s collaborate and take the leap into tomorrow.</p>
          <a
            href={
              process.env.REACT_APP_ENV === 'dev'
                ? 'https://app.dev.preauditai.com'
                : 'https://app.preauditai.com'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn">Get started</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Services
