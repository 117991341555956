import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ROUTES } from 'config'
import NotFound from 'components/core/Utils/NotFoundPage'
import HomePage from 'components/core/HomePage/HomePage'
import ErrorPage from 'components/core/Utils/ErrorPage'
import PrivacyPolicy from 'components/core/Utils/PrivacyPolicy'
import About from 'components/core/About/About'
import AboutStateRamp from 'components/core/About/AboutStateRamp'
import AboutAwsSa from 'components/core/About/Services'
import AboutSoc from 'components/core/About/AboutSoc'
import ContactForm from 'components/core/ContactForm/ContactForm'

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path={ROUTES.HOME} element={<HomePage/>} />
        <Route path={ROUTES.ABOUT} element={<About/>} />
        <Route path={ROUTES.ABOUT_STATE_RAMP} element={<AboutStateRamp/>} />
        <Route path={ROUTES.SERVICES} element={<AboutAwsSa/>} />
        <Route path={ROUTES.ABOUT_SOC} element={<AboutSoc/>} />
        <Route path={ROUTES.CONTACT_US} element={<ContactForm/>} />
        <Route path={ROUTES.ERROR} element={<ErrorPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
    </Routes>
)

export { AppRoutes }
