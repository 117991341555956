import './ContactForm.css'
import Header from '../Header/Header'
// import { coreEndpoint, contact, form } from '../../../constants/core/endpoints'

import { useState } from 'react'
import { endpoints, resources } from 'config'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>()

  const validate = () => {
    if (!name || typeof name !== 'string' || name.length < 5 || name.length > 75) {
      return 'Name should not exceed 75 characters or less than 5 characters'
    }

    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7}$/
    if (!email || !emailPattern.test(email) || [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com',
      'aol.com',
      'protonmail.com',
      'zohomail.com',
      'icloud.com',
      'gmx.com',
      'yandex.com'
    ].includes(email.split('@')[1].toLowerCase())) {
      return 'Company email is required'
    }

    if (!message || typeof message !== 'string' || message.length < 20) {
      return 'Please provide enough content so we can best respond to you.'
    }

    return null
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const validationError = validate()
    if (validationError) {
      setError(validationError)
      return
    }

    setLoading(true)
    setError(null)

    try {
      const response = await fetch(`${endpoints.core}${resources.contactUs}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, message })
      })
      const data = await response.json()
      if (response.ok) {
        if (data?.message) {
          alert(data.message)
        } else {
          alert('Message received, we will respond within 24hrs')
        }
      } else if (response.status.toString().startsWith('4')) {
        if (data?.message) {
          alert(data.message)
        } else {
          alert("Something went wrong, we couldn't send the message!")
        }
      } else {
        alert("Something went wrong, we couldn't send the message!")
      }

      setName('')
      setEmail('')
      setMessage('')
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="space-page">
      <Header />
      <div className="contact-form-container" style={{ marginTop: '5%' }}>
        <h2 className="contact-form-title">We Are Waiting...</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              id="name"
              className="contact-form-input"
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              id="email"
              className="contact-form-input"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea
              id="message"
              className="contact-form-input contact-form-textarea"
              value={message}
              onChange={(e) => { setMessage(e.target.value) }}
            />
          </div>
          <button type="submit" className="contact-form-button" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send'}
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  )
}

export default ContactForm
