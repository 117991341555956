import React from 'react'
import '../../../styles/FooterStyle.css'
const Footer = () => {
  return (
    <footer className="bg-black footer">
      <div className="container mx-auto px-4 text-center">
        <p className="text-white copyrightText">&copy; {new Date().getFullYear()} PreAuditAi, a CloudSec LLC Product. All rights reserved.</p>
        {/* Link to privacy policy */}
        <a href={`${process.env.PUBLIC_URL}/privacy.html`} target="_blank" rel="noopener noreferrer">Privacy Notice</a>
        {/* <a
          href={`${process.env.PUBLIC_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-gray-300"
        >
          Privacy Notice
        </a> */}
      </div>
    </footer>
  )
}

export default Footer
