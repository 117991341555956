import axios from 'axios'
import { endpoints, resources } from 'config'
import { onCLS, onFID, onLCP, onFCP, onINP, onTTFB } from 'web-vitals'

function reportWebVitals (): void {
  onCLS(reportWebVitalsProd)
  onFID(reportWebVitalsProd)
  onLCP(reportWebVitalsProd)
  onFCP(reportWebVitalsProd)
  onINP(reportWebVitalsProd)
  onTTFB(reportWebVitalsProd)
}

function reportWebVitalsProd (metric: any): void {
  const metricsBody = JSON.stringify(metric)
  const url = `${endpoints.core}${resources.lm}`
  axios.post(url, metricsBody, {
    headers: { 'Content-Type': 'application/json' }
  }).then((res) => {
    console.log(res)
  }).catch((error) => {
    console.error('Error reporting web vitals with axios', error)
  })
}

export { reportWebVitals } // Change for prod
