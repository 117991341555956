import Header from '../Header/Header'

const AboutSoc = () => {
  const soc2Solutions = [
    { title: 'Empowering Your Business Through Swift, Accurate, and Effective Compliance Management', content: 'In the digital age, SOC2 compliance is more than just a regulatory requirement - it&apos;s a promise of trust and security to your customers. We understand the daunting challenges you face while navigating the labyrinth of SOC2 compliance and we have the perfect solution.' },
    { title: 'Your Fast-Track to SOC2 Compliance', content: 'With PreAuditAi, we bring forth a revolutionary approach to SOC2 compliance. Our state-of-the-art, generative AI technology converts the long-drawn, often confusing, SOC2 pre-assessment process from months to mere hours. This is not just about speed; it&apos;s about accuracy, agility, and most importantly, peace of mind.' }, { title: 'Holistic and Intelligent Compliance Management', content: 'Our AI-powered solution dives deep into your control environment, identifies potential compliance gaps, and prescribes robust remediation strategies. By automating the arduous and complex tasks associated with SOC compliance, we liberate your team&apos;s time and resources, allowing them to focus on growth and innovation' }, { title: 'Step into the Future of Compliance', content: 'Unlike conventional methods, our AI-driven approach reduces the risk of human error and ensures that your organization maintains the highest level of SOC2 compliance. And, when you need to demonstrate your compliance to stakeholders, our system generates detailed reports to showcase your adherence, strengthening your credibility and competitive edge.' },
    { title: ' Be the Business of Tomorrow', content: '  At PreAuditAi, we believe that compliance should be your strategic advantage, not an obstacle. The SOC2 compliance landscape is evolving, and you should too. Embrace the future with our innovative, AI-led SOC2 compliance solution.\n Ready to transform your SOC2 compliance journey? Get in touch with us today and let&apos;s leverage the power of AI to revolutionize your compliance strategy!' },
    { title: '#AI #SOC2 #Compliance #InformationSecurity #Speed #Efficiency' }
  ]
  return (
    <div className="space-bg min-h-screen">
      <Header />
      <div className="container mx-auto py-16">
        <h1 className="text-4xl text-white font-bold mb-8" style={{ textAlign: 'center' }}>
        Discover PreAuditAi&apos;s SOC2 Compliance Solution
        </h1>
        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 about-container">
        <>
         { soc2Solutions.map((soc, index) => {
           return (
                <div key={index} className="flex-1 about-content">
                <h2 className="text-white text-lg">
               {soc.title}
              </h2>
              <p className="text-white text-lg">
               {soc.content}
               </p>
        </div>
           )
         })}
         </>
        </div>
      </div>
    </div>
  )
}

export default AboutSoc
