import { domain, resources } from 'config'
import { createCoreAxiosInstance } from 'hooks'

const coreAxiosInstance = createCoreAxiosInstance()

async function askTimedOutLocalBiBot (data: { inquiry: string, sessionId: string, chatId: string, tries: number }): Promise<string> {
  try {
    const path = `${domain.bibot}${resources.timedOutLocalQ}`
    const response = await coreAxiosInstance.post<any>(path, data)
    console.log(response)
    return response.data.message
  } catch (error: any) {
    console.log(error.message)
    return error.message
  }
}

async function askLocalBiBot (data: { inquiry: string, endSession?: boolean, sessionId: string, chatId: string }): Promise<string> {
  try {
    const path = `${domain.bibot}${resources.localQ}`
    const response = await coreAxiosInstance.post<any>(path, data)
    console.log(response)
    return response.data.message
  } catch (error1: any) {
    console.log('error1', error1)
    if (error1.code === 'ECONNABORTED' || error1.message.includes('Endpoint request timed out')) {
      try {
        return await askTimedOutLocalBiBot({
          inquiry: data.inquiry,
          sessionId: data.sessionId,
          chatId: data.chatId,
          tries: 2
        })
      } catch (error2: any) {
        if (error2.code === 'ECONNABORTED' || error2.message.includes('Endpoint request timed out')) {
          try {
            return await askTimedOutLocalBiBot({
              inquiry: data.inquiry,
              sessionId: data.sessionId,
              chatId: data.chatId,
              tries: 3
            })
          } catch (error3: any) {
            return error3.message
          }
        } else {
          return error2.message
        }
      }
    } else {
      return error1.message
    }
  }
}

export {
  askLocalBiBot
}
