import axios, { type AxiosInstance } from 'axios'
import { endpoints } from 'config/endpointEnums'

const coreBaseURL = endpoints.core

export const createCoreAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
//   const { addNotification } = useNotifications()
  const instance = axios.create({
    baseURL: coreBaseURL,
    timeout: 60000, // 1 minutes max
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...params
  })

  instance.interceptors.request.use(async (config) => {
    try {
      // const idToken = await getIdToken()
      // config.headers.Authorization = idToken
    } catch (error) {
      console.error('Error attaching token', error)
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      let errMsg = 'An unknown error occurred'
      if (error.response.status === 504) {
        errMsg = 'Endpoint request timed out'
      } else if (error.response) {
        errMsg = error.response.data.message
      } else if (error.request) {
        errMsg = 'No response received from the server.'
      } else {
        errMsg = `Error setting up the request: ${error.message}`
      }
      return await Promise.reject(new Error(errMsg))
    }
  )

  return instance
}
