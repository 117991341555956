const PrivacyPolicy = () => {
  return (
      <div>
        <h2>Our Privacy Notice</h2>
        <iframe
            title="Privacy Policy"
            src={`${process.env.PUBLIC_URL}/privacy.html`}
            width="100%"
            height="1000">
        </iframe>
      </div>
  )
}

export default PrivacyPolicy
