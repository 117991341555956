const isProd = process.env.REACT_APP_ENV === 'prod'
export const endpoints = {
  get core () {
    return isProd ? 'https://api.app.preauditai.com/v0' : 'https://api.app.dev.preauditai.com/v0'
  }
} as const

export enum domain {
  core = '/core',
  bibot = '/bibot',
}

export enum resources {
  contactUs = '/contact-us',
  localQ = '/local-q',
  lm = '/lm',
  log = '/logs',
  metrics = '/metrics',
  timedOutLocalQ = '/t-local-q'
}
