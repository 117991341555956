import { message } from 'antd'
import { appStateEnums } from 'config'
import type React from 'react'
import { useEffect, useRef, useState, useContext } from 'react'
import { AppContext } from 'context'
import { askLocalBiBot } from 'services'
import { v4 as uuidv4 } from 'uuid'

const useBiBotChatBot = (): any => {
  const { state, dispatch } = useContext(AppContext)
  const { chatIsOpen } = state
  const [messages, setMessages] = useState<Array<{ sender: 'user' | 'bot', text: string }>>([])
  const [userInput, setUserInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messageEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageEndRef?.current) { messageEndRef.current.scrollIntoView({ behavior: 'smooth' }) }
  }, [messages])

  const toggleChat = () => {
    dispatch({ type: appStateEnums.BIBOT, chatIsOpen: !chatIsOpen })
  }

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value)
  }

  async function sendInputInquiry () {
    setIsLoading(true)
    try {
      if (userInput.trim()) {
        // dispatch({ type: appStateEnums.BIBOT, chatIsOpen: true })
        setMessages(messages => [...messages, { sender: 'user', text: userInput }])
        const response = await askLocalBiBot({
          inquiry: userInput.trim(),
          sessionId: state.sessionId,
          chatId: uuidv4()
        })
        setMessages(messages => [...messages, { sender: 'bot', text: response }])
        setUserInput('')
      }
    } catch (error: any) {
      void message.error(error?.message ?? 'Failed to get response from server')
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await sendInputInquiry()
    }
  }
  return {
    chatIsOpen,
    messages,
    isLoading,
    messageEndRef,
    userInput,
    handleUserInput,
    handleKeyPress,
    toggleChat,
    sendInputInquiry
  }
}

export { useBiBotChatBot }
